














































import { Aluno,Usuario} from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { AlunoService, UsuarioService} from "@/core/services/geral";
import {Component} from "vue-property-decorator";

@Component
export default class RelatorioClienteUsuario extends PageBase{
    
    service = new AlunoService();
    lista: Aluno [] = [];
    usuarioService = new UsuarioService();
    usuario = new Usuario();

    filtro= {
        usuarioId: 0
    }

    overlay: boolean = false;
    
    mounted() {
        this.filtro.usuarioId = Number(this.$route.query.usuarioId);
        this.Carregar();
    }

    Carregar(){     
        
        this.overlay = true;

        this.service.GetByGestorIndicador(this.filtro.usuarioId,"Usuario").then(
            res =>{
                this.lista  = res.data.items;                
            },
            err => {
                if (!err.response){
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
                else{
                    this.$swal("Aviso",err.response.data,"error")
                }
            }
        ).finally(() => {
            this.overlay = false;
        })

        this.usuarioService.ObterPorId(this.filtro.usuarioId).then(
            res => {
                this.usuario = res.data;
            },
            err => {
                if (!err.response){
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
                else{
                    this.$swal("Aviso",err.response.data,"error")
                }
            }
        )
    }
}
